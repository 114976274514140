@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

body{
  background-color: #161616 !important;
  background-image: url('https://welcometothejunglenfts.com/wp-content/uploads/2023/08/Cats-in-Crowns-No-Name-2048x1230.png');
   
  background-position: ;
 
  background-size: cover;

}
@tailwind base;
@tailwind components;
@tailwind utilities;





.primary-btn{
  @apply  bg-[#E57004] text-white text-[14px]  font-normal px-3 md:px-6 py-3 rounded-md
}